import { graphql, useStaticQuery } from 'gatsby';

export const useImages = () => {
  const images = useStaticQuery(graphql`
    query Images {
      cgLogo: file(relativePath: { eq: "logos/CG-Logo.png" }) {
        ...wideLogo
      }
      dogFestLogo: file(relativePath: { eq: "logos/dogFestLogo.png" }) {
        ...mediumLogo
      }
    }

    fragment wideLogo on File {
      childImageSharp {
        gatsbyImageData(
          width: 255
          height: 50
          layout: CONSTRAINED
          backgroundColor: "transparent"
          aspectRatio: 5.833333333333333
          placeholder: NONE
        )
      }
    }
    fragment mediumLogo on File {
      childImageSharp {
        gatsbyImageData(
          width: 120
          height: 51
          layout: CONSTRAINED
          backgroundColor: "transparent"
          placeholder: NONE
        )
      }
    }
  `);

  return images;
};
